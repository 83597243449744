@import url("tldraw/tldraw.css");

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tlui-debug-panel {
  display: none;
}
